<template>
  <div id="FindOut">
    <div class="posiFixed">
      <div class="titleTop" >
        <div class="active">问答</div>
        <div @click="toCheck('/Connections')">人脉圈</div>
        <div @click="toCheck('/CompanyList')">公司库</div>
        <div @click="toCheck('/ActivityLine?activeName=c')">线下活动</div>
        <div @click="toCheck('/ActivityLine?activeName=d')">保观集市</div>
      </div>
    </div>
    <div>
      <van-popup v-model="show" position="top">
        <div class="topPop">
          <div class="titleTop2 interests">选择你感兴趣的话题<span><img src="../assets/image/findOut/flitWhite.png" />筛选</span></div>
          <div class="titleTab2">
            <div :class="{'active':tagId == ''}" class="active" @click="tagId =''" >全部</div>
            <div :class="{'active':tagId == i.id}" @click="tagId = i.id" v-for="(i,item) in getTags" :key="item">{{i.name}}</div>
          </div>
          <div class="anscontain"><span>重置</span><span @click="checkId">完成</span></div>
        </div>
      </van-popup>
    </div>

    <div class="padTop">
      <div class="titleTop1">
        热门答主
      </div>
      <div class="familiar">
        <div @click="toIntroDetail(i.submit_id)" v-for="(i,item) in hotAnswerer" :key="item">
          <img v-if="i.user"  :src="i.user.headimgurl ? Global.changeImg(i.user.headimgurl) :'../assets/image/home/image1.png'" />
          <p>{{i.name}}</p>
          <p>{{i.quiz_answer_count}}个回答</p>
          <div style="height:0.55rem">
            <div style="min-height:0.15rem"><span v-for="(j,items) in i.tags" :key="items">{{j.name}}</span><span style="opacity:0"></span></div>
          </div>
        </div>
      </div>
    </div>


    
    <div style="margin-top: 0.1rem;">
      <div class="titleTop1 interests">热门话题<span @click="checkTab"><img src="../assets/image/findOut/flitGray.png" />筛选</span></div>
      <div class="titleTab">
        <div :class="{'active':tagId == ''}" @click="changeId('')">全部</div>
        <div :class="{'active':tagId == i.id}" v-for="(i,item) in getTags" :key="item" @click="changeId(i.id)">{{i.name}}</div>
      </div>
      <div class="hotMain">
        <div @click="toDetail(i.id)" v-for="(i,item) in getDocList" :key="item">
          <p class="second">{{i.question}}</p>
          <div class="first" v-if="i.quiz_answer" >
            <div>
              <img   :src="i.quiz_answer.user.headimgurl ? Global.changeImg(i.quiz_answer.user.headimgurl) :'../assets/image/home/image1.png'" />
              <span v-if="i.quiz_answer">{{i.quiz_answer.member_info.name}}</span>回答了
            </div>
          </div>
          <p class="sixth" v-if="i.quiz_answer">{{i.quiz_answer.upvote}}人赞同了该回答</p>
          <p class="third" v-if="i.quiz_answer"><img src="../assets/image/home/answer.png" />{{i.quiz_answer.answer}}</p>
          <div class="forth" v-if="i.quiz_answer">
            <div>
              <span>
                {{i.quiz_answer.upvote}}
                <van-icon @click.stop="noLike(i.quiz_answer.id,item)" v-if="i.quiz_answer.upvote_log.length" name="good-job" style="font-size:0.15rem"  color="#FF5562" />
                <van-icon @click.stop="toLike(i.quiz_answer.id,item)"  v-else name="good-job-o" style="font-size:0.15rem"  color="#FF5562" />
                <!-- @click.stop="toStar(i.id)" -->
              </span>
              <span @click.stop="showEd = true;quizId=i.quiz_answer.id;quItem = item">
                {{i.quiz_answer.commentCount}} <van-icon  name="comment-o" style="font-size:0.15rem"  color="#3890FF" />
                <!-- <img v-for="(j,items) in i.circusee"  :key="items" :src="Global.changeImg(j.head_img.headimgurl)" /> 等{{i.circusee_count}}人已围观 -->
              </span>
            </div>
            <div>
              <span>{{Global.clearWhite($moment(new Date(Date.parse((i.quiz_answer.created_at)))).fromNow())}}</span>
            </div>
          </div>
          <p class="sixth" style="margin-top:0.1rem;margin-bottom: 0;" v-else>等你来回答~</p>
        </div>
      </div>
    </div>

    <span class="addMore" @click="addQuestion">
      <van-icon style="font-size:0.23rem;" color="#F6F7FD" name="plus" />
    </span>
    <van-popup v-model="showEd" position="bottom"  >
      <div  class="editSure">
        <van-field
          class="edit"
          v-model="revert"
          rows="2"
          autosize
          type="textarea"
          maxlength="50"
          placeholder="想说点什么"
          show-word-limit
        />
        <div >
          <span @click="toRever">确定</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import start from '../assets/image/findOut/start.png'
import startn from '../assets/image/findOut/startn.png'
export default {
  name: "FindOut",
  data(){
    return{
      quizId:'',
      quItem:'',
      revert:'',//评论
      showEd:false,
      show:false,
      hotAnswerer:'',//热门答主
      getTags:'',//话题分类
      tagId:'',//分类id
      getDocList:'',//列表
      loading:false,
      finished:false,
      next_page_url:''
    }
  },
  mounted() {
    // 热门答主
    this.axios({
      method: 'GET',
      url: '/quiz/hotAnswerer',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.hotAnswerer = res.data.data
      }
    })
    // 话题分类
    this.axios({
      method: 'GET',
      url: '/quiz/getTags',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      async:false
    }).then((res) => {
      if(res.data.code == 0){
        this.getTags = res.data.data
      }
    })
    // 列表
    this.axios({
      method: 'GET',
      url: '/quiz/getQuizList',
      headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
    }).then((res) => {
      if(res.data.code == 0){
        this.getDocList = res.data.data.data
        this.next_page_url = res.data.data.next_page_url
      }
    })

  },
  methods:{
    // 点击评论
    toRever(){
      this.axios({
        method: 'POST',
        url: '/quiz/userReplyQuiz',
        data:{
          revert:this.revert,
          quizId:this.quizId,
        },
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.showEd = false
          this.revert = ''
          this.quizId = ''
          this.getDocList[this.quItem].quiz_answer.commentCount++
          this.$toast('评论成功,待审核')
          // 评论数目加一
        }
      })
    },
    // 点赞
    noLike(docId,index){
      this.axios({
          method: 'PATCH',
          url: '/quiz/upvoteQuiz',
          data:{
            status:0,
            type:6,
            linkId:docId
          },
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            // var index = this.getCollectIds.indexOf(docId);
            this.getDocList[index].quiz_answer.upvote_log = []
            this.getDocList[index].quiz_answer.upvote--
            this.$toast({
              message: '取消点赞',
              icon: startn,
            })
          }
        })
    },
    toLike(docId,index){
      this.axios({
        method: 'PATCH',
        url: '/quiz/upvoteQuiz',
        data:{
          status:1,
          type:6,
          linkId:docId
        },
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.getDocList[index].quiz_answer.upvote_log.push([{id:docId}])
          this.getDocList[index].quiz_answer.upvote++
          this.$toast({
            message: '点赞成功',
            icon: start,
          })
        }
      })
    },
    firstLoad(){
      window.scrollTo(0,0)
      this.axios({
        method: 'GET',
        url: '/quiz/getQuizList?tagId='+this.tagId,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.loading = false
          this.finished = false
          this.getDocList = []
          if(res.data.data){
            this.getDocList = res.data.data.data;
            this.next_page_url = res.data.next_page_url
            console.log(this.next_page_url)
          }else{
            this.next_page_url = ''
          }
          if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
            this.finished = true
          }
        }
      })
    },
    onLoad(){
      if(this.next_page_url){
        this.axios({
          method: 'GET',
          url: this.next_page_url,
          headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        }).then((res) => {
          if(res.data.code == 0){
            this.loading = false
            if(res.data.data){
              this.getDocList.push(...res.data.data.data)
              this.next_page_url = res.data.next_page_url
            }else{
              this.next_page_url = ''
            }
            if(!this.next_page_url || !res.data.data.data.length || !res.data.data){
              this.finished = true
            } 
          }
        })
      }else{
        this.loading = false;
        this.finished = true
      }
    },
    checkId(){
      this.show = false
      this.firstLoad()
    },
    // 改变id
    changeId(id){
      this.tagId = id
      this.firstLoad()
    },
    // 弹框
    checkTab(){
      this.show = true
    },
    // 跳转
    toCheck(path){
      this.$router.push({
        path: path,
      })
    },
    // 提问题跳转问题页面
    addQuestion(){
      this.$router.push({
        path: '/Question',
      })
    },
    // 问答详情
    toDetail(id){
      this.$router.push({
        path: '/QuestionDetail?id='+id,
      })
    },
    // 跳转大咖详情
    toIntroDetail(id){
      this.axios({
        method: 'PATCH',
        url: '/master/alwaysVisitAdd',
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
        data:{
          masterId:id
        }
      }).then((res) => {
        if(res.data.code == 0){
          this.$router.push({name:'Introduction',query: {id:id}})
          
        }
      })
    },
  }
};
</script>
<style lang="less" scoped>
#FindOut {
  .editSure{
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #16171D;
    >div:last-child{
      text-align: right;
      padding: 0 0.15rem 0.11rem;
      >span{
        height: 0.3rem;
        line-height: 0.3rem;
        border-radius: 0.3rem;
        padding: 0 0.2rem;
        background: #3890FF;
        color: #F6F7FD;
        font-size: 0.13rem;
        display: inline-block;
      }
    }
  }
  .padTop{
    padding-top: 0.52rem;
  }
  .topPop{
    .anscontain{
      display: flex;
      height: 0.4rem;
      border-top: 1px solid #5C5D6E61;
      >span{
        flex:1;
        text-align: center;
        line-height: 0.4rem;
        color: #989AB1;
        font-size: 0.14rem;
      }
      >span:nth-child(2){
        color: #3890FF;
        border-left: 1px solid #5C5D6E61;
      }
    }
  }
  .addMore{
    position: fixed;
    bottom: 0.8rem;
    width: 0.45rem;
    height: 0.45rem;
    background: linear-gradient(345deg, #3890FF 0%, #1B78EE 100%);
    box-shadow: 0px 0px 5px rgba(9, 104, 223, 0.39);
    border-radius: 50%;
    right: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    i{
      font-weight: 900;
    }
  }
  .hotMain{
    padding: 0 0.15rem;
    >div{
      padding: 0.18rem 0;
      border-bottom: .1px solid #5C5D6E61;

      .sixth{
        color: #727387;
        margin-bottom: 0.1rem;
        font-size: 0.12rem;
      }

      .forth{
        >div:last-child{
          display: flex;
          >span{
            color: #727387;
            display: flex;
            align-items: center;
            font-size: 0.12rem;
          }
        }
        >div:first-child{
          color: #989AB1;
          display: flex;
          align-items: center;
          font-size: 0.12rem;
          >span{
            margin-right: 0.1rem;
          }
          >img{
            width: 0.14rem;
            border-radius: 50%;
          }
          >img:nth-child(n+2){
            margin-left: -0.03rem;
          }
          >img:last-child{
            margin-right: 0.05rem;
          }
        }
      }
      .third{
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #989AB1;
        font-size: 0.12rem;
        line-height: 0.17rem;
        margin-bottom: 0.15rem;
        >img{
          width: 0.14rem;
          display: inline-block;
          line-height: 0.17rem;
          margin-bottom: -0.02rem;
        }
      }
      .second{
        color: #F6F7FD;
        font-size: 0.14rem;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .first{
        margin: 0.11rem 0 0.12rem;
        >div{
          color: #727387;
          font-size: 0.12rem;
          display: flex;
          align-items: center;
          >span{
            margin: 0 0.1rem 0 0.08rem;
          }
          >img{
            width: 0.19rem;
            border-radius: 50%;
          }
        }
        >span{
          color: #727387;
          font-size: 0.12rem;
        }
      }
      >div{
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  .titleTab,.titleTab2{
    >div{
      display: inline-block;
      height: 0.25rem;
      border-radius: 0.25rem;
      padding: 0 0.12rem;
      line-height: 0.26rem;
      background: #3D404F;
      color: #989AB1;
      font-size: 0.12rem;
      margin-right: 0.12rem;
    }
    .active{
      background: #3890FF;
      color: #F6F7FD;
    }
  } 
  .titleTab2{
    padding-left: 0.15rem;
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 0.1rem;
    >div{
      margin-bottom: 0.1rem;
    }
  }
  .titleTab{
    overflow-x: scroll;
    padding-left: 0.15rem;
    white-space: nowrap;
  }
  .interests{
    display: flex;
    justify-content: space-between;
    >span{
      color: #989AB1;
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      margin-right: 0.05rem;
      >img{
        width: 0.12rem;
        margin-right: 0.05rem;
      }
    }
  }
  .familiar{
    overflow-x: scroll;
    padding-left: 0.15rem;
    margin-bottom: 0.15rem;
    white-space: nowrap;
    >div{
      width: 1.39rem;
      border-radius: 0.05rem;
      margin-right: 0.15rem;
      display: inline-block;
      background:#3D404F;
      text-align: center;
      >div{
        >div{
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          >span{
            padding: 0 0.05rem;
            color: #75B2FF;
            font-size: 0.12rem;
            border-radius: 0.02rem;
            background: #2862AC;
            margin: 0 0.05rem 0.05rem 0;
          }
        }
      }
      >img{
        display: inline-block;
        width: 0.54rem;
        margin: 0.1rem 0 0.1rem;
        border-radius: 50%;
      }
      >p:nth-child(2){
        color: #FFFFFF;
        font-size: 0.14rem;
      }
      >p:nth-child(3){
        color: #9091A3;
        font-size: 0.12rem;
        margin: 0.03rem 0 0.06rem;
      }
      >span{
        display: inline-block;
        color: #9091A3;
        font-size: 0.13rem;
        height: 0.27rem;
        border-radius: 0.27rem;
        line-height: 0.27rem;
        color: #fff;
        background:#3890FF;
        padding: 0 0.11rem;
      }
    }
  }
  > div {
    background: #23252e;
    overflow: auto;
  }
  .titleTop2{
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #989AB1;
    font-size: 0.14rem;
    font-weight: 600;
    >span{
      color: #F6F7FD;
      font-size: 0.12rem;
    };
  }
  .titleTop1{
    padding: 0.15rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #FFFFFF;
    font-size: 0.16rem;
    font-weight: 600;
  }
  .posiFixed{
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10;
  }
  .titleTop {
    z-index: 10;
    padding: 0.15rem;
    display: flex;
    align-items: center;
    font-size: 0.14rem;
    border-bottom: .1px solid #5C5D6E61;
    color: #989ab1;
    > div {
      margin-right: 0.2rem;
      position: relative;
    }
    .active {
      font-weight: 600;
      color: #f6f7fd;
      font-size: 0.14rem;
    }
    .active::after {
      position: absolute;
      content: "";
      width: 0.2rem;
      height: 2px;
      background: #3890ff;
      left: 0;
      right: 0;
      margin: auto;
      bottom: -0.05rem;
    }
  }
}
</style>